@import "src/styles/index";

@include widescreen-xxl {
}
@include desktop-xl {
}
@include laptop-lg {
    .app_container {
        text-align: center;
        padding: 0;
        margin: 0;
        .grid1 {
            padding-top: 40px;
            padding-bottom: 40px;
            height: 100%;
            .box {
                //background: #cfe8fc;
                height: 100%;
                margin-bottom: 40px;
                .heading {
                    margin-bottom: 40px;
                }
                .grid_content {
                    height: 100%;
                }
            }
        }
        .grid2 {
            padding-top: 40px;
            padding-bottom: 40px;
            height: 100%;
            background: #cfe8fc;
            .box {
                //background: #cfe8fc;
                height: 100%;
                margin-bottom: 40px;
                .heading {
                    margin-bottom: 40px;
                }
                .grid_content {
                    height: 100%;
                }
            }
        }

        .grid3 {
            padding-top: 40px;
            margin-bottom: 40px;
            height: 100%;
            //background: #cfe8fc;
            .box {
                //background: #cfe8fc;
                height: 100%;
                margin-bottom: 40px;
                .heading {
                    margin-bottom: 40px;
                }
                .grid_content {
                    height: 100%;
                }
            }
        }

        .grid4 {
            padding-top: 40px;
            margin-bottom: 40px;
            height: 100%;
            background: #cfe8fc;
            .box {
                //background: #cfe8fc;
                height: 100%;
                margin-bottom: 40px;

                .heading {
                    margin-bottom: 40px;

                    h2 {
                        font-size: 21px;
                    }
                    h4 {
                        font-size: 14px;
                        padding-top: 20px;
                    }
                }

                .grid_content {
                    height: 100%;
                }
            }
        }

        .footer_wrapper {
            //height: 700px;
            //background-color: $palette_info_dark;
            //color: white;
            //z-index: 4;
            background-color: $palette_info_dark;
            color: white;
        }
    }
}
@include tablet-md {
    .app_container {
        text-align: center;
        padding: 0;
        margin: 0;
        .grid1 {
            padding-top: 40px;
            padding-bottom: 40px;
            height: 100%;
            .box {
                //background: #cfe8fc;
                height: 100%;
                margin-bottom: 40px;
                .heading {
                    margin-bottom: 40px;
                }
                .grid_content {
                    height: 100%;
                }
            }
        }
        .grid2 {
            padding-top: 40px;
            padding-bottom: 40px;
            height: 100%;
            background: #cfe8fc;
            .box {
                //background: #cfe8fc;
                height: 100%;
                margin-bottom: 40px;
                .heading {
                    margin-bottom: 40px;
                }
                .grid_content {
                    height: 100%;
                }
            }
        }

        .grid3 {
            padding-top: 40px;
            margin-bottom: 40px;
            height: 100%;
            //background: #cfe8fc;
            .box {
                //background: #cfe8fc;
                height: 100%;
                margin-bottom: 40px;
                .heading {
                    margin-bottom: 40px;
                }
                .grid_content {
                    height: 100%;
                }
            }
        }

        .grid4 {
            padding-top: 40px;
            margin-bottom: 40px;
            height: 100%;
            background: #cfe8fc;
            .box {
                //background: #cfe8fc;
                height: 100%;
                margin-bottom: 40px;

                .heading {
                    margin-bottom: 40px;

                    h2 {
                        font-size: 21px;
                    }
                    h4 {
                        font-size: 14px;
                        padding-top: 20px;
                    }
                }

                .grid_content {
                    height: 100%;
                }
            }
        }

        .footer_wrapper {
            //background-color: $palette_info_dark;
            //color: white;
            //z-index: 4;
            //height: 1500px;
            background-color: $palette_info_dark;
            color: white;
        }
    }
}
@include mobile-sm {
    .app_container {
        text-align: center;
        padding: 0;
        margin: 0;
        .grid1 {
            padding-top: 40px;
            padding-bottom: 40px;
            height: 100%;
            .box {
                //background: #cfe8fc;
                height: 100%;
                margin-bottom: 40px;
                .heading {
                    margin-bottom: 40px;
                }
                .grid_content {
                    height: 100%;
                }
            }
        }
        .grid2 {
            padding-top: 40px;
            padding-bottom: 40px;
            height: 100%;
            background: #cfe8fc;
            .box {
                //background: #cfe8fc;
                height: 100%;
                margin-bottom: 40px;
                .heading {
                    margin-bottom: 40px;
                }
                .grid_content {
                    height: 100%;
                }
            }
        }

        .grid3 {
            padding-top: 40px;
            margin-bottom: 40px;
            height: 100%;
            //background: #cfe8fc;
            .box {
                //background: #cfe8fc;
                height: 100%;
                margin-bottom: 40px;
                .heading {
                    margin-bottom: 40px;
                }
                .grid_content {
                    height: 100%;
                }
            }
        }

        .grid4 {
            padding-top: 40px;
            margin-bottom: 40px;
            height: 100%;
            background: #cfe8fc;
            .box {
                //background: #cfe8fc;
                height: 100%;
                margin-bottom: 40px;

                .heading {
                    margin-bottom: 40px;

                    h2 {
                        font-size: 21px;
                    }
                    h4 {
                        font-size: 14px;
                        padding-top: 20px;
                    }
                }

                .grid_content {
                    height: 100%;
                }
            }
        }

        .footer_wrapper {
            //background-color: $palette_info_dark;
            //color: white;
            //z-index: 4;
            background-color: $palette_info_dark;
            color: white;
        }
    }
}
@include mobile-xs {
    .app_container {
        text-align: center;
        padding: 0;
        margin: 0;
        .grid1 {
            padding-top: 40px;
            padding-bottom: 40px;
            height: 100%;
            .box {
                //background: #cfe8fc;
                height: 100%;
                margin-bottom: 40px;
                .heading {
                    margin-bottom: 40px;
                }
                .grid_content {
                    height: 100%;
                }
            }
        }
        .grid2 {
            padding-top: 40px;
            padding-bottom: 40px;
            height: 100%;
            background: #cfe8fc;
            .box {
                //background: #cfe8fc;
                height: 100%;
                margin-bottom: 40px;
                .heading {
                    margin-bottom: 40px;
                }
                .grid_content {
                    height: 100%;
                }
            }
        }

        .grid3 {
            padding-top: 40px;
            margin-bottom: 40px;
            height: 100%;
            //background: #cfe8fc;
            .box {
                //background: #cfe8fc;
                height: 100%;
                margin-bottom: 40px;
                .heading {
                    margin-bottom: 40px;
                }
                .grid_content {
                    height: 100%;
                }
            }
        }

        .grid4 {
            padding-top: 40px;
            margin-bottom: 40px;
            height: 100%;
            background: #cfe8fc;
            .box {
                //background: #cfe8fc;
                height: 100%;
                margin-bottom: 40px;

                .heading {
                    margin-bottom: 40px;

                    h2 {
                        font-size: 21px;
                    }
                    h4 {
                        font-size: 14px;
                        padding-top: 20px;
                    }
                }

                .grid_content {
                    height: 100%;
                }
            }
        }

        .footer_wrapper {
            //background-color: $palette_info_dark;
            //color: white;
            //z-index: 4;
            background-color: $palette_info_dark;
            color: white;
        }
    }
}
@include mobile-xxs {
    .app_container {
        text-align: center;
        padding: 0;
        margin: 0;
        .grid1 {
            padding-top: 40px;
            padding-bottom: 40px;
            height: 100%;
            .box {
                //background: #cfe8fc;
                height: 100%;
                margin-bottom: 40px;
                .heading {
                    margin-bottom: 40px;
                }
                .grid_content {
                    height: 100%;
                }
            }
        }
        .grid2 {
            padding-top: 40px;
            padding-bottom: 40px;
            height: 100%;
            background: #cfe8fc;
            .box {
                //background: #cfe8fc;
                height: 100%;
                margin-bottom: 40px;
                .heading {
                    margin-bottom: 40px;
                }
                .grid_content {
                    height: 100%;
                }
            }
        }

        .grid3 {
            padding-top: 40px;
            margin-bottom: 40px;
            height: 100%;
            //background: #cfe8fc;
            .box {
                //background: #cfe8fc;
                height: 100%;
                margin-bottom: 40px;
                .heading {
                    margin-bottom: 40px;
                }
                .grid_content {
                    height: 100%;
                }
            }
        }

        .grid4 {
            padding-top: 40px;
            margin-bottom: 40px;
            height: 100%;
            background: #cfe8fc;
            .box {
                //background: #cfe8fc;
                height: 100%;
                margin-bottom: 40px;

                .heading {
                    margin-bottom: 40px;

                    h2 {
                        font-size: 21px;
                    }
                    h4 {
                        font-size: 14px;
                        padding-top: 20px;
                    }
                }

                .grid_content {
                    height: 100%;
                }
            }
        }

        .footer_wrapper {
            //background-color: $palette_info_dark;
            //color: white;
            //z-index: 4;
            background-color: $palette_info_dark;
            color: white;
        }
    }
}
