.modal_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    gap: 2px;
    transform: translate(-50%, -50%);
    width: 380px;
    height: 380px;
    background-color: white;
    borderradius: 1px;
    boxshadow: 24;
}

.box_wrapper {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 380px;
    height: 380px;
    background-color: white;
    boxshadow: 24;
    border-radius: 4px;
    padding: 30px;
}

.closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    cursor: pointer;
}

.btn_wrapper {
    display: flex;
    padding-top: 30px;
}

.btn {
    width: 320px;
    height: 45px;
}

.btn_wrapper_add {
    display: flex;
    margin-left: 5px;
    gap: 10px;
    padding-top: 10px;
}

.textField {
    padding-top: 20px;
    width: 100%;
}

.subheader {
    padding-top: 20px;
    max-width: 320px;
    text-align: center;
}

.icon_logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.logo_name {
    font-size: 24px;
}
img {
    height: auto;
    width: 60px;
    fill: red;
}
.logo_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
