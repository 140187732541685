@import "src/styles/index";

@include widescreen-xxl {
}
@include desktop-xl {
}
@include laptop-lg {
    .box {
        padding-bottom: 60px;
        .project {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
        }
    }
}

@include tablet-md {
}
@include mobile-sm {
}
@include mobile-xs {
}
@include mobile-xxs {
}
