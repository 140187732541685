@import "src/styles/index";

.services_grid1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 400px;
    .btn3 {
        width: 220px;
        height: 40px;
        font-size: 12px;
    }
    .btn_wrapper {
        display: flex;
        justify-content: center;
        gap: 10px;
        .btn1 {
            width: 170px;
            height: 40px;
        }
        .btn2 {
            width: 170px;
            height: 40px;
        }
    }
    .descr {
        font-size: 16px;
    }
}
.grid3_wrapper {
    flex-direction: row;

    .services_grid3 {
        display: flex;
        flex-direction: column;
        height: 300px;
        gap: 20px;
        .services_grid3_content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding-left: 40px;

            text-decoration: dotted;
            gap: 10px;

            .mark_icon {
                background: $palette_info_light;
            }

            .gc_li {
                display: flex;
                justify-content: flex-start;
                .descr {
                    font-size: 16px;
                    text-align: start;
                }
            }
        }
    }
}

.box {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 40px;
    //width: 1500px;
    .sidebar {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0px;
        width: auto;
        height: 2850px;
        border: 1px solid lightgrey;
        //padding: 20px;
        padding-top: 20px;
        .main_heading {
            font-size: 32px;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 20px;
        }
    }
    .grid2_wrapper {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 40px 40px;
        //margin-right: 20px;

        .services_grid2 {
            display: flex;
            flex-direction: column;
            //align-items: flex-start;
            justify-content: center;
            padding: 0;

            .card_wrapper {
                text-align: center;
                height: 550px;
                max-width: 474px;
                padding: 20px;
                .heading {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }

                .img_wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: auto;

                    img {
                        width: 424px;
                    }
                }
                .content_wrapper {
                }
            }
            .services_grid3_content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding-left: 40px;

                text-decoration: dotted;
                gap: 10px;

                .mark_icon {
                    background: $palette_info_light;
                }

                .gc_li {
                    display: flex;
                    justify-content: flex-start;
                    .descr {
                        font-size: 16px;
                        text-align: start;
                    }
                }
            }
        }
    }
}

@import "GridComponent.media";
