@import "src/styles/index";

.panel_partners {
    display: flex;
    justify-content: center;
    width: 300px;
    //height: 80px;
    height: 100px;
    //background: #03a9f4;
    margin-right: 10px;
    border-radius: 5px;
    //text-align: center;
    img {
        //display: block;
        width: auto;
        height: 60px;
    }
}

.panel_reviews {
    display: flex;
    justify-content: center;
    width: 300px;
    //height: 80px;
    height: 300px;
    //background: #03a9f4;
    margin-right: 10px;
    border-radius: 5px;
    //text-align: center;
    .card_wrapper {
        text-align: center;
        .img_wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: auto;
            height: 120px;

            img {
                width: auto;
                height: auto;
            }
        }
        a {
            text-decoration: none;
            color: $palette_info_light;
            font-size: 12px;
        }
    }
}
