@import "src/styles/index";
.container {
    display: flex;
    flex-direction: column;
    .footer {
        text-align: center;
        //height: 300px;
        gap: 20px;
        .footer_wrap {
            display: flex;
            justify-content: space-between;
            padding-top: 30px;
            padding-bottom: 30px;
            .footer_one {
                display: flex;
                justify-content: space-between;
                gap: 200px;
                .footer_study {
                    h4 {
                        margin-bottom: 30px;
                        text-align: start;
                    }
                    ul {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 10px;
                        li {
                            cursor: pointer;
                            .footer_link {
                                text-decoration: none;
                                color: white;
                            }
                        }
                    }
                }
                .footer_service {
                    h4 {
                        margin-bottom: 30px;
                        text-align: start;
                    }
                    ul {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 10px;
                        li {
                            cursor: pointer;
                            .footer_link {
                                text-decoration: none;
                                color: white;
                            }
                        }
                    }
                }
            }
            .footer_two {
                display: flex;
                justify-content: space-between;
                gap: 200px;
                .footer_interesting {
                    h4 {
                        margin-bottom: 30px;
                        text-align: start;
                    }
                    ul {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 10px;
                        li {
                            cursor: pointer;
                            .footer_link {
                                text-decoration: none;
                                color: white;
                            }
                        }
                    }
                }
                .footer_contacts {
                    h4 {
                        margin-bottom: 30px;
                        text-align: start;
                    }
                    ul {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 10px;
                        //li {
                        //    display: flex;
                        //    flex-direction: column;
                        //    align-items: flex-start;
                        //    justify-content: flex-start;
                        //}
                        li {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 3px;
                            cursor: pointer;
                            .footer_link {
                                text-decoration: none;
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }

    .footer_bottom {
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
        align-items: center;
        gap: 10px;
        padding-top: 30px;
        padding-bottom: 30px;
        width: 100%;
        border-top: 1px dotted white;
        text-align: center;
        .btn {
            width: 170px;
            height: 40px;
        }
    }
}

@import "Footer.media";
