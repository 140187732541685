//primary
$palette_primary_light: #42a5f5;
$palette_primary_main: #1976d2;
$palette_primary_dark: #1565c0;
//secondary
$palette_secondary_light: #ba68c8;
$palette_secondary_main: #9c27b0;
$palette_secondary_dark: #7b1fa2;
//error
$palette_error_light: #ef5350;
$palette_error_main: #d32f2f;
$palette_error_dark: #c62828;
//warning
$palette_warning_light: #ff9800;
$palette_warning_main: #ed6c02;
$palette_warning_dark: #e65100;
//info
$palette_info_light: #03a9f4;
$palette_info_main: #0288d1;
$palette_info_dark: #01579b;
//success
$palette_success_light: #4caf50;
$palette_success_main: #2e7d32;
$palette_success_dark: #1b5e20;
//custom
$palette_info_light_custom: rgb(255, 95, 45);
$palette_info_main_custom: #0288d1;
$palette_info_dark_custom: #01579b;
$palette_info_main_custom: #c5e8ff;
