@import "src/styles/index";

@include widescreen-xxl {
}

@include desktop-xl {
    .tenderContainer {
        .grid1 {
            padding-top: 200px; //350px
            padding-bottom: 40px;
            height: 100%;
            background: #cfe8fc;

            .box {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                height: 400px;

                .heading {
                    text-align: center;
                    max-width: 800px;

                    h2 {
                        font-size: 28px;
                    }
                }

                .subtitle {
                    max-width: 800px;

                    p {
                        font-size: 16px;
                        line-height: 24px;
                        margin: 0 auto;
                        text-align: center;
                    }
                }
            }

            .btn {
                margin-top: 50px;
                height: 50px;
                width: max-content;
            }
        }

        .grid2 {
            padding-top: 100px;
            padding-bottom: 100px;
            height: 100%;
            //background: #cfe8fc;
            .box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                height: 600px;
            }
        }

        .grid3 {
            padding-top: 100px;
            padding-bottom: 0px;
            height: 100%;
            background: #cfe8fc;

            .box {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 40px;
                height: 600px;

                .heading {
                    text-align: center;
                    h2 {
                        font-size: 28px;
                    }
                }

                .subtitle {
                    max-width: 600px;

                    p {
                        font-size: 16px;
                        line-height: 24px;
                        margin: 0 auto;
                        text-align: center;
                    }
                }

                .bottom_moto_list {
                    display: flex;
                    justify-content: space-between;
                    gap: 20px;
                    padding-top: 20px;

                    .moto {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 200px;
                        h4 {
                            text-align: center;
                            font-size: 18px;
                        }

                        p {
                            text-align: center;
                            font-size: 14px;
                            line-height: 21px;
                        }
                    }

                    .icon_group {
                        width: 60px;
                        height: 60px;
                        border-radius: 80px;
                        border: 1px solid white;
                        padding: 5px;
                    }
                }
            }

            .btn {
                margin-top: 40px;
                height: 50px;
                width: max-content;
            }
        }

        .footer_wrapper {
            background-color: $palette_info_dark;
            color: white;
        }
    }
}

@include laptop-lg {
    .tenderContainer {
        .grid1 {
            padding-top: 350px;
            padding-bottom: 40px;
            height: 100%;
            background: #cfe8fc;

            .box {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                height: 400px;

                .heading {
                    text-align: center;
                    max-width: 800px;

                    h2 {
                        font-size: 28px;
                    }
                }

                .subtitle {
                    max-width: 800px;

                    p {
                        font-size: 16px;
                        line-height: 24px;
                        margin: 0 auto;
                        text-align: center;
                    }
                }
            }

            .btn {
                margin-top: 50px;
                height: 50px;
                width: max-content;
            }
        }

        .grid2 {
            padding-top: 100px;
            padding-bottom: 100px;
            height: 100%;
            //background: #cfe8fc;
            .box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                height: 600px;
            }
        }

        .grid3 {
            padding-top: 100px;
            padding-bottom: 0px;
            height: 100%;
            background: #cfe8fc;

            .box {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 40px;
                height: 600px;

                .heading {
                    text-align: center;
                    h2 {
                        font-size: 28px;
                    }
                }

                .subtitle {
                    max-width: 600px;

                    p {
                        font-size: 16px;
                        line-height: 24px;
                        margin: 0 auto;
                        text-align: center;
                    }
                }

                .bottom_moto_list {
                    display: flex;
                    justify-content: space-between;
                    gap: 20px;
                    padding-top: 20px;

                    .moto {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 200px;
                        h4 {
                            text-align: center;
                            font-size: 18px;
                        }

                        p {
                            text-align: center;
                            font-size: 14px;
                            line-height: 21px;
                        }
                    }

                    .icon_group {
                        width: 60px;
                        height: 60px;
                        border-radius: 80px;
                        border: 1px solid white;
                        padding: 5px;
                    }
                }
            }

            .btn {
                margin-top: 40px;
                height: 50px;
                width: max-content;
            }
        }

        .footer_wrapper {
            background-color: $palette_info_dark;
            color: white;
        }
    }
}

@include tablet-md {
    .tenderContainer {
        .grid1 {
            padding-top: 300px;
            padding-bottom: 40px;
            height: 100%;
            background: #cfe8fc;

            .box {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                height: 400px;

                .heading {
                    text-align: center;
                    max-width: 800px;

                    h2 {
                        font-size: 28px;
                    }
                }

                .subtitle {
                    max-width: 800px;

                    p {
                        font-size: 16px;
                        line-height: 24px;
                        margin: 0 auto;
                        text-align: center;
                    }
                }
            }

            .btn {
                margin-top: 50px;
                height: 50px;
                width: max-content;
            }
        }

        .grid2 {
            padding-top: 50px;
            padding-bottom: 100px;
            height: 100%;
            //background: #cfe8fc;
            .box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                height: 600px;
            }
        }

        .grid3 {
            padding-top: 100px;
            padding-bottom: 0px;
            height: 100%;
            background: #cfe8fc;

            .box {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 40px;
                height: 600px;

                .heading {
                    text-align: center;
                    h2 {
                        font-size: 28px;
                    }
                }

                .subtitle {
                    max-width: 600px;

                    p {
                        font-size: 16px;
                        line-height: 24px;
                        margin: 0 auto;
                        text-align: center;
                    }
                }

                .bottom_moto_list {
                    display: flex;
                    justify-content: space-between;
                    gap: 20px;
                    padding-top: 20px;

                    .moto {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 150px;
                        h4 {
                            text-align: center;
                            font-size: 16px;
                        }

                        p {
                            text-align: center;
                            font-size: 12px;
                            line-height: 18px;
                        }
                    }

                    .icon_group {
                        width: 60px;
                        height: 60px;
                        border-radius: 80px;
                        border: 1px solid white;
                        padding: 5px;
                    }
                }
            }

            .btn {
                margin-top: 40px;
                height: 50px;
                width: max-content;
            }
        }

        .footer_wrapper {
            background-color: $palette_info_dark;
            color: white;
        }
    }
}

@include mobile-sm {
    .tenderContainer {
        .grid1 {
            padding-top: 200px;
            padding-bottom: 40px;
            height: 100%;
            background: #cfe8fc;

            .box {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                height: 400px;

                .heading {
                    text-align: center;
                    max-width: 800px;

                    h2 {
                        font-size: 24px;
                        line-height: 30px;
                    }
                }

                .subtitle {
                    max-width: 800px;

                    p {
                        font-size: 14px;
                        line-height: 20px;
                        margin: 0 auto;
                        text-align: center;
                    }
                }
            }

            .btn {
                margin-top: 50px;
                height: 40px;
                font-size: 10px;
                width: max-content;
            }
        }

        .grid2 {
            padding-top: 50px;
            padding-bottom: 100px;
            height: 100%;
            //background: #cfe8fc;
            .box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                height: 600px;
            }
        }

        .grid3 {
            padding-top: 100px;
            padding-bottom: 0px;
            height: 100%;
            background: #cfe8fc;

            .box {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 40px;
                height: 600px;

                .heading {
                    text-align: center;
                    h2 {
                        font-size: 24px;
                        line-height: 30px;
                    }
                }

                .subtitle {
                    max-width: 600px;

                    p {
                        font-size: 14px;
                        line-height: 20px;
                        margin: 0 auto;
                        text-align: center;
                    }
                }

                .bottom_moto_list {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding-top: 20px;
                    .moto {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        width: 150px;
                        h4 {
                            text-align: center;
                            font-size: 14px;
                        }

                        p {
                            display: none;
                            text-align: center;
                            font-size: 12px;
                            line-height: 12px;
                        }
                    }

                    .icon_group {
                        width: 60px;
                        height: 60px;
                        border-radius: 60px;
                        border: 1px solid white;
                        padding: 5px;
                    }
                }
            }

            .btn {
                margin-top: 20px;
                height: 40px;
                font-size: 10px;
                width: max-content;
            }
        }

        .footer_wrapper {
            background-color: $palette_info_dark;
            color: white;
        }
    }
}

@include mobile-xs {
    .tenderContainer {
        .grid1 {
            padding-top: 200px;
            padding-bottom: 40px;
            height: 100%;
            //background: #cfe8fc;

            .box {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                height: 400px;
                width: 100%;

                .heading {
                    text-align: center;
                    max-width: 800px;

                    h2 {
                        font-size: 24px;
                        line-height: 30px;
                    }
                }

                .subtitle {
                    max-width: 800px;

                    p {
                        font-size: 14px;
                        line-height: 20px;
                        margin: 0 auto;
                        text-align: center;
                    }
                }
            }

            .btn {
                margin-top: 50px;
                height: 40px;
                font-size: 10px;
                width: max-content;
            }
        }

        .grid2 {
            padding-top: 50px;
            padding-bottom: 100px;
            height: 100%;
            //background: #cfe8fc;
            .box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                height: 600px;
            }
        }

        .grid3 {
            padding-top: 50px;
            padding-bottom: 0px;
            height: 100%;
            background: #cfe8fc;

            .box {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                height: 600px;

                .heading {
                    text-align: center;
                    h2 {
                        font-size: 24px;
                        line-height: 30px;
                    }
                }

                .subtitle {
                    max-width: 600px;

                    p {
                        font-size: 14px;
                        line-height: 20px;
                        margin: 0 auto;
                        text-align: center;
                    }
                }

                .bottom_moto_list {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding-top: 10px;
                    .moto {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        width: 150px;
                        h4 {
                            text-align: center;
                            font-size: 14px;
                        }

                        p {
                            display: none;
                            text-align: center;
                            font-size: 12px;
                            line-height: 12px;
                        }
                    }

                    .icon_group {
                        width: 60px;
                        height: 60px;
                        border-radius: 60px;
                        border: 1px solid white;
                        padding: 5px;
                    }
                }
            }

            .btn {
                margin-top: 20px;
                height: 40px;
                font-size: 10px;
                width: max-content;
            }
        }

        .footer_wrapper {
            background-color: $palette_info_dark;
            color: white;
        }
    }
}

@include mobile-xxs {
}
