@import "src/styles/index";

@include widescreen-xxl {
    .container {
        display: flex;
        flex-direction: column;
        .footer {
            text-align: center;
            .footer_wrap {
                display: flex;
                justify-content: space-between;
                padding-top: 30px;
                padding-bottom: 30px;
                .footer_one {
                    display: flex;
                    justify-content: center;
                    gap: 120px;
                    .footer_study {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                        }
                    }

                    .footer_service {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                        }
                    }
                }

                .footer_two {
                    display: flex;
                    justify-content: space-between;
                    gap: 120px;
                    .footer_interesting {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                        }
                    }

                    .footer_contacts {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;

                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;
                            }
                        }
                    }
                }
            }
        }

        .footer_bottom {
            display: flex;
            justify-content: center;
            flex-direction: column-reverse;
            align-items: center;
            gap: 10px;
            padding-top: 30px;
            padding-bottom: 30px;
            width: 100%;
            border-top: 1px dotted white;
            .btn {
                width: 170px;
                height: 40px;
            }
        }
    }
}
@include desktop-xl {
    .container {
        display: flex;
        flex-direction: column;
        .footer {
            text-align: center;
            .footer_wrap {
                display: flex;
                justify-content: space-between;
                padding-top: 30px;
                padding-bottom: 30px;
                .footer_one {
                    display: flex;
                    justify-content: space-between;
                    gap: 100px;
                    .footer_study {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }
                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            font-size: 12px;
                            gap: 10px;
                        }
                    }
                    .footer_service {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }
                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            font-size: 12px;
                            gap: 10px;
                        }
                    }
                }
                .footer_two {
                    display: flex;
                    justify-content: space-between;
                    gap: 100px;
                    .footer_interesting {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }
                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                        }
                    }
                    .footer_contacts {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }
                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;
                            }
                        }
                    }
                }
            }
            .footer_bottom {
                display: flex;
                justify-content: space-between;
                flex-direction: row-reverse;
                align-items: center;
                gap: 30px;
                width: 100%;
                border-top: 1px dotted white;
                padding-top: 30px;
                padding-bottom: 30px;
                .btn {
                    width: 170px;
                    height: 40px;
                }
            }
        }
    }
}
@include laptop-lg {
    .container {
        display: flex;
        flex-direction: column;

        .footer {
            text-align: center;

            .footer_wrap {
                display: flex;
                justify-content: flex-start;
                padding-top: 30px;
                padding-bottom: 30px;
                gap: 200px;
                .footer_one {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 50px;

                    .footer_study {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            font-size: 14px;
                        }
                    }

                    .footer_service {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            font-size: 14px;
                        }
                    }
                }

                .footer_two {
                    display: flex;
                    justify-content: space-between;
                    gap: 50px;
                    flex-direction: column;

                    .footer_interesting {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            font-size: 14px;
                        }
                    }

                    .footer_contacts {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            font-size: 14px;

                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;
                            }
                        }
                    }
                }
            }
        }

        .footer_bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 30px;
            width: 100%;
            border-top: 1px dotted white;
            padding-top: 30px;
            padding-bottom: 30px;

            .btn {
                width: 170px;
                height: 40px;
            }
        }
    }
}
@include tablet-md {
    .container {
        display: flex;
        flex-direction: column;

        .footer {
            text-align: center;

            .footer_wrap {
                display: flex;
                justify-content: flex-start;
                padding-top: 30px;
                padding-bottom: 30px;
                gap: 100px;

                .footer_one {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 50px;

                    .footer_study {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            font-size: 14px;
                        }
                    }

                    .footer_service {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            font-size: 14px;
                        }
                    }
                }

                .footer_two {
                    display: flex;
                    justify-content: space-between;
                    gap: 50px;
                    flex-direction: column;

                    .footer_interesting {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            font-size: 14px;
                        }
                    }

                    .footer_contacts {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            font-size: 14px;

                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;
                            }
                        }
                    }
                }
            }
        }

        .footer_bottom {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: space-between;
            padding-top: 30px;
            padding-bottom: 30px;
            width: 100%;
            border-top: 1px dotted white;
            gap: 10px;

            .btn {
                width: 170px;
                height: 40px;
            }
        }
    }
}
@include mobile-sm {
    .container {
        display: flex;
        flex-direction: column;

        .footer {
            text-align: center;

            .footer_wrap {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding-top: 30px;
                padding-bottom: 30px;
                gap: 100px;

                .footer_one {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 50px;

                    .footer_study {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            font-size: 14px;
                        }
                    }

                    .footer_service {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            font-size: 14px;
                        }
                    }
                }

                .footer_two {
                    display: flex;
                    justify-content: space-between;
                    gap: 50px;
                    flex-direction: column;

                    .footer_interesting {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            font-size: 14px;
                        }
                    }

                    .footer_contacts {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            font-size: 14px;

                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;
                            }
                        }
                    }
                }
            }
        }

        .footer_bottom {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: space-between;
            padding-top: 30px;
            padding-bottom: 30px;
            width: 100%;
            border-top: 1px dotted white;

            .btn {
                width: 170px;
                height: 40px;
            }
        }
    }
}
@include mobile-xs {
    .container {
        display: flex;
        flex-direction: column;

        .footer {
            text-align: center;

            .footer_wrap {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 100px;

                .footer_one {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 50px;

                    .footer_study {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            font-size: 14px;
                        }
                    }

                    .footer_service {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            font-size: 14px;
                        }
                    }
                }

                .footer_two {
                    display: flex;
                    justify-content: space-between;
                    gap: 50px;
                    flex-direction: column;

                    .footer_interesting {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            font-size: 14px;
                        }
                    }

                    .footer_contacts {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            font-size: 14px;

                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;
                            }
                        }
                    }
                }
            }
        }

        .footer_bottom {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: space-between;
            padding-top: 30px;
            padding-bottom: 30px;
            width: 100%;
            border-top: 1px dotted white;

            .btn {
                width: 170px;
                height: 40px;
            }
        }
    }
}
@include mobile-xxs {
    .container {
        display: flex;
        flex-direction: column;

        .footer {
            text-align: center;

            .footer_wrap {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 100px;

                .footer_one {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 50px;

                    .footer_study {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            font-size: 14px;
                        }
                    }

                    .footer_service {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            font-size: 14px;
                        }
                    }
                }

                .footer_two {
                    display: flex;
                    justify-content: space-between;
                    gap: 50px;
                    flex-direction: column;

                    .footer_interesting {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            font-size: 14px;
                        }
                    }

                    .footer_contacts {
                        h4 {
                            margin-bottom: 30px;
                            text-align: start;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            font-size: 14px;

                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;
                            }
                        }
                    }
                }
            }
        }

        .footer_bottom {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: space-between;
            padding-top: 30px;
            padding-bottom: 30px;
            width: 100%;
            border-top: 1px dotted white;

            .btn {
                width: 170px;
                height: 40px;
            }
        }
    }
}
