h1{
  //font-size: inherit;
  font-size: 40px;
  font-weight: 400;
}
h2{
  //font-size: inherit;
  font-size: 32px;
  font-weight: 400;
}
h3{
  //font-size: inherit;
  font-size: 21px;
  font-weight: 400;
}
h4{
  //font-size: inherit;
  font-size: 18px;
  font-weight: 400;
}
h5{
  //font-size: inherit;
  font-size: 16px;
  font-weight: 400;
}
h6 {
  //font-size: inherit;
  font-size: 14px;
  font-weight: 400;
}