@import "src/styles/index";

@include widescreen-xxl {
}
@include desktop-xl {
    .box {
        display: flex;
        justify-content: space-between;
        margin-top: 120px;
        margin-bottom: 20px;
        gap: 30px;
        .address {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            //margin-right: 10px;
            h2 {
                margin-bottom: 40px;
            }
            ul {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 3px;
                .add {
                    font-size: 14px;
                }
                .phone {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding-top: 30px;
                    font-size: 16px;
                }
                .mail {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding-top: 10px;
                    color: $palette_primary_light;
                }
                .services {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding-top: 10px;
                    font-size: 12px;
                    color: gray;
                }
            }
        }
        .map {
            img {
                height: auto;
                width: 600px;
            }
        }
    }

    .box1 {
        //background: #cfe8fc;
    }
}
@include laptop-lg {
    .box {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-top: 100px;
        margin-bottom: 20px;
        gap: 30px;
        .address {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            //margin-right: 10px;
            h2 {
                margin-bottom: 40px;
            }
            ul {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 3px;
                .add {
                    font-size: 14px;
                }
                .phone {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding-top: 30px;
                    font-size: 16px;
                }
                .mail {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding-top: 10px;
                    color: $palette_primary_light;
                }
                .services {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding-top: 10px;
                    font-size: 12px;
                    color: gray;
                }
            }
        }
        .map {
            img {
                height: auto;
                width: 600px;
            }
        }
    }

    .box1 {
        //background: #cfe8fc;
    }
}
@include tablet-md {
    .box {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-top: 80px;
        margin-bottom: 0px;
        gap: 30px;
        .address {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            //margin-right: 10px;
            h2 {
                margin-bottom: 40px;
            }
            ul {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 3px;
                .add {
                    font-size: 14px;
                }
                .phone {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding-top: 30px;
                    font-size: 16px;
                }
                .mail {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding-top: 10px;
                    color: $palette_primary_light;
                }
                .services {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding-top: 10px;
                    font-size: 12px;
                    color: gray;
                }
            }
        }
        .map {
            img {
                height: auto;
                width: 400px;
            }
        }
    }

    .box1 {
        //background: #cfe8fc;
    }
}
@include mobile-sm {
}
@include mobile-xs {
    .box {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-top: 80px;
        margin-bottom: 0px;
        gap: 30px;
        .address {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            h2 {
                font-size: 32px;
                margin-bottom: 20px;
            }
            ul {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 3px;
                .add {
                    font-size: 14px;
                }
                .phone {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding-top: 30px;
                    font-size: 16px;
                }
                .mail {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding-top: 10px;
                    color: $palette_primary_light;
                }
                .services {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding-top: 10px;
                    font-size: 12px;
                    color: gray;
                }
            }
        }
        .map {
            padding-top: 40px;
            text-align: center;
            img {
                height: auto;
                width: 300px;
            }
        }
    }

    .box1 {
        //background: #cfe8fc;
    }
}
@include mobile-xxs {
}
