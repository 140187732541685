@import "src/styles/index";

@include widescreen-xxl {
}
@include desktop-xl {
}
@include laptop-lg {
}
@include tablet-md {
}
@include mobile-sm {
}
@include mobile-xs {
    .box {
        margin-bottom: 40px;
        margin-bottom: 0px;
        .heading {
            h4 {
                margin-bottom: 40px;
                text-align: center;
            }
        }
        .grid_content {
            height: 100%;
        }
    }
}
@include mobile-xxs {
}
