@import "src/styles/index";

@include widescreen-xxl {
    .box {
        position: relative;
        height: 800px;
        display: flex;
        align-items: center;
        //width: 100vw;

        overflow: hidden;
        background-color: transparent;
        .img {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            width: 100vw;
            height: 800px;
            z-index: -1;
            filter: brightness(70%);
        }
        .content_wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            .title_wrapper {
                display: flex;
                justify-content: flex-start;
                /*font-size: 32px;*/
                color: white;
                text-align: start;
                max-width: 720px;
                width: 100%;
                .title {
                    font-size: 44px;
                }
            }
            .subtitle_wrapper {
                display: flex;
                justify-content: flex-start;
                color: white;
                text-align: start;
                padding-top: 20px;
                max-width: 720px;
                width: 100%;
                .subtitle {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
            .btn_wrapper {
                .content_btn {
                    margin-top: 10px;
                    width: 200px;
                    height: 50px;
                }
                .hiddenBtn {
                    display: none;
                }
            }
        }
        .stepper {
            display: flex;
            justify-content: space-between;
            position: absolute;
            bottom: 0px;
            width: 100%;
            /*background-color: transparent;*/
        }
    }
}

@include desktop-xl {
}

@include laptop-lg {
    .box {
        position: relative;
        height: 800px;
        display: flex;
        align-items: center;
        //width: 100vw;
        overflow: hidden;
        background-color: transparent;
        .img {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            width: 100vw;
            height: 800px;
            z-index: -1;
            filter: brightness(70%);
        }
        .content_wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .title_wrapper {
                display: flex;
                justify-content: flex-start;
                color: white;
                text-align: start;
                max-width: 720px;
                width: 100%;
                padding-top: 100px;
                .title {
                    font-size: 32px;
                }
            }
            .subtitle_wrapper {
                display: flex;
                justify-content: flex-start;
                color: white;
                text-align: start;
                padding-top: 20px;
                max-width: 720px;
                width: 100%;
                .subtitle {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
            .btn_wrapper {
                .content_btn {
                    margin-top: 10px;
                    width: 200px;
                    height: 50px;
                }
                .hiddenBtn {
                    display: none;
                }
            }
        }
        .stepper {
            display: flex;
            justify-content: space-between;
            position: absolute;
            bottom: 0px;
            width: 100%;
            /*background-color: transparent;*/
        }
    }
}

@include tablet-md {
    .box {
        position: relative;
        height: 800px;
        display: flex;
        align-items: center;
        //width: 100vw;
        overflow: hidden;
        background-color: transparent;
        .img {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            width: 100vw;
            height: 800px;
            z-index: -1;
            filter: brightness(70%);
        }
        .content_wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .title_wrapper {
                display: flex;
                justify-content: flex-start;
                color: white;
                text-align: start;
                max-width: 720px;
                width: 100%;
                padding-top: 50px;
                .title {
                    font-size: 32px;
                }
            }
            .subtitle_wrapper {
                display: flex;
                justify-content: flex-start;
                color: white;
                text-align: start;
                padding-top: 20px;
                max-width: 720px;
                width: 100%;
                .subtitle {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
            .btn_wrapper {
                .content_btn {
                    margin-top: 10px;
                    width: 200px;
                    height: 50px;
                }
                .hiddenBtn {
                    display: none;
                }
            }
        }
        .stepper {
            display: flex;
            justify-content: space-between;
            position: absolute;
            bottom: 0px;
            width: 100%;
            /*background-color: transparent;*/
        }
    }
}

@include mobile-sm {
    .box {
        position: relative;
        height: 800px;
        display: flex;
        align-items: center;
        //width: 100vw;
        overflow: hidden;
        background-color: transparent;
        .img {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            width: 100vw;
            height: 800px;
            z-index: -1;
            filter: brightness(70%);
        }
        .content_wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .title_wrapper {
                display: flex;
                justify-content: flex-start;
                color: white;
                text-align: start;
                max-width: 720px;
                width: 100%;
                padding-top: 50px;
                .title {
                    font-size: 32px;
                }
            }
            .subtitle_wrapper {
                display: flex;
                justify-content: flex-start;
                color: white;
                text-align: start;
                padding-top: 20px;
                max-width: 720px;
                width: 100%;
                .subtitle {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            .btn_wrapper {
                .content_btn {
                    margin-top: 10px;
                    width: 150px;
                    height: 40px;
                    font-size: 10px;
                }
                .hiddenBtn {
                    display: none;
                }
            }
        }
        .stepper {
            display: flex;
            justify-content: space-between;
            position: absolute;
            bottom: 0px;
            width: 100%;
            /*background-color: transparent;*/
        }
    }
}

@include mobile-xs {
    .box {
        position: relative;
        height: 800px;
        display: flex;
        align-items: center;
        //width: 100vw;
        overflow: hidden;
        background-color: transparent;
        .img {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            width: 100vw;
            height: 800px;
            z-index: -1;
            filter: brightness(70%);
        }
        .content_wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            .title_wrapper {
                display: flex;
                justify-content: center;
                color: white;
                text-align: start;
                max-width: 720px;
                width: 100%;
                padding-top: 10px;
                .title {
                    font-size: 24px;
                    text-align: center;
                }
            }
            .subtitle_wrapper {
                display: flex;
                justify-content: center;
                color: white;
                text-align: start;
                padding-top: 10px;
                max-width: 720px;
                width: 100%;
                .subtitle {
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                }
            }
            .btn_wrapper {
                display: flex;
                justify-content: center;
                //flex-direction: row;
                gap: 5px;
                .content_btn {
                    margin-top: 10px;
                    width: 140px;
                    height: 30px;
                    font-size: 10px;
                }
                .hiddenBtn {
                    display: block;
                    .btn {
                        margin-top: 10px;
                        width: 140px;
                        height: 30px;
                        font-size: 10px;
                    }
                }
            }
        }
        .stepper {
            display: flex;
            justify-content: space-between;
            position: absolute;
            bottom: 0px;
            width: 100%;
            /*background-color: transparent;*/
        }
    }
}

@include mobile-xxs {
    .box {
        position: relative;
        height: 800px;
        display: flex;
        align-items: center;
        //width: 100vw;
        overflow: hidden;
        background-color: transparent;
        .img {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            width: 100vw;
            height: 800px;
            z-index: -1;
            filter: brightness(70%);
        }
        .content_wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .title_wrapper {
                display: flex;
                justify-content: flex-start;
                color: white;
                text-align: start;
                max-width: 720px;
                width: 100%;
                .title {
                    font-size: 24px;
                }
            }
            .subtitle_wrapper {
                display: flex;
                justify-content: flex-start;
                color: white;
                text-align: start;
                padding-top: 5px;
                max-width: 720px;
                width: 100%;
                .subtitle {
                    font-size: 12px;
                    line-height: 15px;
                }
            }
            .content_btn {
                margin-top: 10px;
                width: 150px;
                height: 40px;
                font-size: 10px;
            }
        }
        .stepper {
            display: flex;
            justify-content: space-between;
            position: absolute;
            bottom: 0px;
            width: 100%;
            /*background-color: transparent;*/
        }
    }
}
