//NotoSans
@font-face {
  font-family: "NotoSans_ThinItalic";
  src: url("../assets/fonts/NotoSans/NotoSans-ThinItalic.ttf") format("ttf");
}
@font-face {
  font-family: "NotoSans_Thin";
  src: url("../assets/fonts/NotoSans/NotoSans-Thin.ttf") format("ttf");
}
@font-face {
  font-family: "NotoSans_SemiBoldItalic";
  src: url("../assets/fonts/NotoSans/NotoSans-SemiBoldItalic.ttf") format("ttf");
}
@font-face {
  font-family: "NotoSans_SemiBold";
  src: url("../assets/fonts/NotoSans/NotoSans-SemiBold.ttf") format("ttf");
}
@font-face {
  font-family: "NotoSans_Regular";
  src: url("../assets/fonts/NotoSans/NotoSans-Regular.ttf") format("ttf");
}
@font-face {
  font-family: "NotoSans_MediumItalic";
  src: url("../assets/fonts/NotoSans/NotoSans-MediumItalic.ttf") format("ttf");
}
@font-face {
  font-family: "NotoSans_Medium";
  src: url("../assets/fonts/NotoSans/NotoSans-Medium.ttf") format("ttf");
}
@font-face {
  font-family: "NotoSans_LightItalic";
  src: url("../assets/fonts/NotoSans/NotoSans-LightItalic.ttf") format("ttf");
}
@font-face {
  font-family: "NotoSans_Light";
  src: url("../assets/fonts/NotoSans/NotoSans-Light.ttf") format("ttf");
}
@font-face {
  font-family: "NotoSans_Italic";
  src: url("../assets/fonts/NotoSans/NotoSans-Italic.ttf") format("ttf");
}
@font-face {
  font-family: "NotoSans_ExtraLightItalic";
  src: url("../assets/fonts/NotoSans/NotoSans-ExtraLightItalic.ttf") format("ttf");
}
@font-face {
  font-family: "NotoSans_ExtraLight";
  src: url("../assets/fonts/NotoSans/NotoSans-ExtraLight.ttf") format("ttf");
}
@font-face {
  font-family: "NotoSans_ExtraBold";
  src: url("../assets/fonts/NotoSans/NotoSans-ExtraBold.ttf") format("ttf");
}
@font-face {
  font-family: "NotoSans_BoldItalic";
  src: url("../assets/fonts/NotoSans/NotoSans-BoldItalic.ttf") format("ttf");
}
@font-face {
  font-family: "NotoSans_Bold";
  src: url("../assets/fonts/NotoSans/NotoSans-Bold.ttf") format("ttf");
}
@font-face {
  font-family: "NotoSans_BlackItalic";
  src: url("../assets/fonts/NotoSans/NotoSans-BlackItalic.ttf") format("ttf");
}
@font-face {
  font-family: "NotoSans_Black";
  src: url("../assets/fonts/NotoSans/NotoSans-Black.ttf") format("ttf");
}

//OleoScript
@font-face {
  font-family: "OleoScript_Bold";
  src: url("../assets/fonts/OleoScript/OleoScriptSwashCaps-Bold.ttf") ;
}
@font-face {
  font-family: "OleoScript_Regular";
  src: url("../assets/fonts/OleoScript/OleoScriptSwashCaps-Regular.ttf") ;
}
