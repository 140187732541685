@import "src/styles/index";
.box {
    position: relative;
    height: 900px;
    display: flex;
    align-items: center;
    //width: 100vw;

    overflow: hidden;
    background-color: transparent;
    .img {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100vw;
        height: 900px;
        z-index: -1;
        filter: brightness(70%);
    }
    .content_wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        .title_wrapper {
            display: flex;
            justify-content: flex-start;
            /*font-size: 32px;*/
            color: white;
            text-align: start;
            max-width: 720px;
            width: 100%;
            .title {
                font-size: 44px;
            }
        }
        .subtitle_wrapper {
            display: flex;
            justify-content: flex-start;
            color: white;
            text-align: start;
            padding-top: 20px;
            max-width: 720px;
            width: 100%;
            .subtitle {
                font-size: 20px;
                line-height: 30px;
            }
        }
        .btn_wrapper {
            .hiddenBtn {
                display: none;
            }
            .footer_link {
                text-decoration: none;
                color: $palette_info_main;
                .content_btn {
                    margin-top: 10px;
                    width: 200px;
                    height: 50px;
                }
            }
        }
    }
    .stepper {
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 0px;
        width: 100%;
        /*background-color: transparent;*/
    }
}

@import "SliderComponent.media";
