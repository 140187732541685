.btn_up {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  bottom: 30px;
  right: 30px;
  background: #cfe8fc;
  border-radius: 50px;
  z-index: 100;
  border: 1px solid white;

}

.btn_chat_opened {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //height: 50px;
  width: 60px;
  bottom: 100px;
  right: 30px;
  background: #cfe8fc;
  border-radius: 50px;
  z-index: 100;
  border: 1px solid white;
  .message_icon_opened {
    transition: transform 1s ease-out;
    transform: rotate(0.37turn);
  }
  .message_icon_closed {
    transition: transform 1s ease-out;
    transform: rotate(0turn);
  }
}

.btn_chat_closed {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  bottom: 100px;
  right: 30px;
  background: #cfe8fc;
  border-radius: 50px;
  z-index: 100;
  border: 1px solid white;
}