@mixin widescreen-xxl() {
  @media (max-width: $breakpoint_xxl) {
    @content;
  }
}

@mixin desktop-xl() {
  @media (max-width: $breakpoint_xl) {
    @content;
  }
}

@mixin laptop-lg() {
  @media (max-width: $breakpoint_lg) {
    @content;
  }
}

@mixin tablet-md() {
  @media (max-width: $breakpoint_md) {
    @content;
  }
}

@mixin mobile-sm() {
  @media (max-width: $breakpoint_sm) {
    @content;
  }
}

@mixin mobile-xs() {
  @media (max-width: $breakpoint_xs) {
    @content;
  }
}

@mixin mobile-xxs() {
  @media (max-width: $breakpoint_xxs) {
    @content;
  }
}


