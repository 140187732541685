.box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    height: 100%;

    .heading {
        margin-bottom: 40px;
        text-align: center;
        max-width: 1000px;
        h3 {
            font-size: 32px;
        }
    }

    .subtitle {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        max-width: 1490px;
        gap: 40px;

        .icon_group {
            width: 150px;
            height: auto;
        }

        .article_text {
            display: flex;
            flex-direction: column;
            gap: 40px;
            padding-top: 40px;

            p {
                font-size: 18px;
                line-height: 24px;
                margin: 0 auto;
                text-align: start;
            }
        }
    }
}
