@import "src/styles/index";

.tenderContainer {
    .grid1 {
        padding-top: 350px;
        padding-bottom: 40px;
        height: 100%;
        background: #cfe8fc;

        .box {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
            height: 400px;
            .heading {
                text-align: center;
            }

            .subtitle {
                max-width: 1000px;

                p {
                    font-size: 18px;
                    line-height: 24px;
                    margin: 0 auto;
                    text-align: center;
                }
            }
        }

        .btn {
            margin-top: 50px;
            height: 50px;
            width: max-content;
        }
    }

    .grid2 {
        padding-top: 100px;
        padding-bottom: 100px;
        height: 100%;
        //background: #cfe8fc;
        .box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            height: 600px;
        }
    }

    .grid3 {
        padding-top: 100px;
        padding-bottom: 0px;
        height: 100%;
        background: #cfe8fc;

        .box {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 40px;
            height: 600px;

            .heading {
                text-align: center;
            }

            .subtitle {
                max-width: 1000px;

                p {
                    font-size: 18px;
                    line-height: 24px;
                    margin: 0 auto;
                    text-align: center;
                }
            }

            .bottom_moto_list {
                display: flex;
                justify-content: space-between;
                gap: 100px;
                padding-top: 20px;

                .moto {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    width: 300px;

                    p {
                        text-align: center;
                        font-size: 14px;
                        line-height: 21px;
                    }
                }

                .icon_group {
                    width: 80px;
                    height: 80px;
                    border-radius: 80px;
                    border: 1px solid white;
                    padding: 5px;
                }
            }
        }
        .btn_art {
            display: flex;
            justify-content: center;
            gap: 10px;
            padding-top: 20px;
        }

        .btn {
            //margin-top: 40px;
            height: 50px;
            width: max-content;
            .redir {
                color: white;
            }
        }
    }

    .footer_wrapper {
        background-color: $palette_info_dark;
        color: white;
    }
}

@import "Page.media";
